<template>
	<div class="container">
		<component :is="component" :class="urlValue == '' ? 'component-container':'component-container-full'"></component>
		<van-tabbar v-if="urlValue == ''" v-model="component">
			<van-tabbar-item v-for="(item, key) in navs" :key="key" :name="item.component" icon-prefix="icon" :icon="item.icon">{{ item.name }}
			</van-tabbar-item>
		</van-tabbar>
		
		<el-dialog
			id="playerVideo"
			title="随机巡查"
			:visible.sync="playVideoDialog"
			:before-close="handlePlayVideoClose"
			:append-to-body="true"
			:destroy-on-close="true"
			:center="true"
			custom-class="player-video-dialog"
			width="100%" 
		>
			<div class="video-container" style="height: 80vh;"> <!-- Adjust the height as needed -->
				<iframe
					id="ysOpenDevice"
					:src="iframeSrc"
					width="100%"
					height="100%"
					allowfullscreen
					frameborder="0"
				/>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Login from '../login/index.vue'
	import Map from './map.vue'
	import Video from './video.vue'
	import Kaoqin from './kaoqin.vue'
	import { getQueryString } from '@/utils/utils'
	import { getIframeSrc } from '@/utils/video';
	export default {
		components: {
			Login,
			Map,
			Video,
			Kaoqin,
		},
		data() {
			return {
				component: 'Map',
				navs: [{
					icon: 'map',
					name: '地图',
					component: 'Map'
				}, {
					icon: 'kaoqin',
					name: '考勤',
					component: 'Kaoqin'
				}, {
					icon: 'camera',
					name: '摄像头',
					component: 'Video'
				}],
				urlValue:'',
				playVideoDialog: false,
				iframeSrc: '',
				playerId: '',
				playerTitle: ''
			}
		},
		created() {
			var value = getQueryString('component')
			console.log(value)
			if(value != null && value != '') {
				this.component = value
				this.urlValue = value
			}
		},
		methods: {
			async showCameraDialog(type, data) {
				if (type === 'camera') {
					this.iframeSrc = await getIframeSrc(data.videoUrl);
					this.$nextTick(() => {
					this.playVideoDialog = true;
					this.playerId = data.cameraId;
					this.playerTitle = data.name;
					});
				}
				data.deviceStatus = 1; // 临时设置为全部在线
				if (data.deviceStatus !== 1) {
					this.$message({
					message: '摄像头不在线',
					type: 'warning'
					});
					return;
				}
			},
			handlePlayVideoClose() {
				this.playVideoDialog = false;
				this.iframeSrc = '';
				this.playerId = '';
				this.playerTitle = '';
			}
		}
	}
</script>
<style>
	.container {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
	
	.component-container {
		width: 100%;
		height: calc(100%);
		overflow: auto;
	}
	.component-container-full {
		width: 100%;
		height: 100%;
		overflow: auto;
	}
	.el-dialog--center .el-dialog__body{
		padding: 5px 0px 5px 0px
	}

</style>