export async function getIframeSrc(url) {
    try {
      const response = await fetch('https://open.ys7.com/api/lapp/token/get', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: 'appKey=7162e8bcf0734f82affcd9f965e421ec&appSecret=948b482c297125a2e7470fb7f7196b50'
      })
      const data = await response.json()
      const accessToken = data.data.accessToken
  
      return `https://open.ys7.com/ezopen/h5/iframe?url=${encodeURIComponent(url)}&autoplay=1&audio=1&accessToken=${accessToken}`
    } catch (error) {
      console.error('Error obtaining access token:', error)
      return ''
    }
  }